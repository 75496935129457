import React from "react"
import classNames from 'classnames';

import { graphql } from "gatsby"
import { useIntl } from 'gatsby-plugin-intl'

import ReactMarkdown from 'react-markdown';

import PresentationBox from "../components/presentationBox";

import HeaderImage from '../components/imageHeader';
import styles from '../styles/about.module.css';
import '../styles/index.css';

import Layout from "../components/layout";

const Sobre = ({ data }) => {
    const intl = useIntl()

    data = data.allStrapiSobre.edges[0].node;

    const isEnglish = intl.locale === 'en'

    return (
        <Layout titlePage={isEnglish ? "About Us" : "Sobre Nós"}>
            <HeaderImage title={isEnglish ? "About Us" : "Sobre Nós"} />

            <div className={classNames("container", styles.aboutContainer)}>
                <main>
                    <div className={styles.boxTxtImgAbout}>
                        <section className={styles.secPrimaryText}>
                            <ReactMarkdown
                                source={isEnglish && data.textoEn ? data.textoEn : data.textoPt}
                                transformImageUri={
                                    uri => uri.startsWith('http') ?
                                        uri : `${process.env.GATSBY_API_URL}${uri}`
                                            }
                            />
                        </section>

                        <img src={isEnglish && data.mapaEn ? data.mapaEn.localFile.publicURL : data.mapaPt.localFile.publicURL} alt={isEnglish && data.mapaEn ? data.mapaEn.name : data.mapaPt.name} className={styles.imageMap} />
                    </div>

                    <div className="video-sobre">
                    <iframe src="https://www.youtube.com/embed/zAW7TuCX418" title='Vídeo Institucional da GlobalTrevo Consulting'>

                    </iframe>
                    </div>

                    <hr />

                    <div className={styles.boxPresentation}>
                        {data.colaboradores.map((elem, index) => <div key={index} className={styles.contentPres}><PresentationBox name={elem.nome} avatar={elem.foto.localFile.publicURL} text={isEnglish && elem.textoEn ? elem.textoEn : elem.textoPt} linkedinLink={elem.linkedinLink} /></div>)}
                    </div>
                    <div className="whatsAppLogo">
                        <a href="https://wa.me/5511976282854" title="Whatsapp" className='wppFixed' >
                            <img src="/wpp.svg" alt="" />
                        </a>
                    </div>
                </main>
            </div>

        </Layout>
    )
}

export const query = graphql`
query{
    allStrapiSobre {
        edges {
            node {
                textoPt
                textoEn
              	mapaPt {
                  localFile {
                      publicURL
                  }
                }
                mapaEn {
                  localFile {
                    publicURL
                  }
                }
                colaboradores {
                    nome
                    foto {
                        name
                        localFile {
                          publicURL
                        }
                    }
                    textoPt
                    textoEn
                    linkedinLink
                }
            }
        }
    }
}
`

export default Sobre
